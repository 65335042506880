var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-app-bar',{attrs:{"app":"","color":"white"}},[(_vm.logoUrl)?_c('v-img',{staticClass:"hidden-sm-and-down",attrs:{"src":_vm.logoUrl,"contain":"","max-width":"200","max-height":"50","position":"top left"}}):_vm._e(),(_vm.logoText)?_c('div',{staticClass:"text-overline primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" "+_vm._s(_vm.logoText)+" ")]):_vm._e(),_c('v-spacer'),_c('v-toolbar-items',[(!_vm.$vuetify.breakpoint.smAndDown)?[_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-circle-outline ")]),_vm._v(" "+_vm._s(_vm.userInfo && _vm.userInfo.email)+" ")],1)]:_vm._e(),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.logout}},[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-divider',{attrs:{"vertical":""}})],2)],1),_c('v-navigation-drawer',{attrs:{"app":"","dark":"","color":"black","mini-variant":!_vm.$vuetify.breakpoint.mobile && _vm.mini,"value":!_vm.$vuetify.breakpoint.mobile || !_vm.mini},on:{"input":function($event){_vm.$vuetify.breakpoint.mobile && (_vm.mini = !$event)}}},[_c('v-list',{attrs:{"dense":"","nav":""}},[(!_vm.$vuetify.breakpoint.mobile)?_c('v-tooltip',{attrs:{"disabled":!_vm.mini,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){_vm.mini = !_vm.mini}}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[(_vm.mini)?[_vm._v(" fas fa-bars ")]:[_vm._v(" fas fa-arrow-left ")]],2)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase"},[(_vm.mini)?[_vm._v(" Menu ")]:[_vm._v(" Menu ")]],2)],1)],1)]}}],null,false,4011021421)},[_c('span',[(_vm.mini)?[_vm._v(" Menu ")]:[_vm._v(" Menu ")]],2)]):_vm._e(),_vm._l((_vm.items),function(item){return [_c('v-tooltip',{key:item.title,attrs:{"disabled":!_vm.mini,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.condition || item.condition())?_c('v-list-item',_vm._g(_vm._b({attrs:{"to":item.to,"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]})],2)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"mb-2"},[_c('v-icon',{on:{"click":function($event){_vm.mini = !_vm.mini}}},[(_vm.mini)?[_vm._v(" mdi-menu ")]:[_vm._v(" mdi-menu ")]],2)],1):_vm._e(),_c('router-view',{key:_vm.$route.path.split('/').slice(0, 4).join('/')})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"multi-line":"","elevation":"24","top":"","right":"","timeout":"-1"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.$store.commit('setErrorMessage', '')}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.$store.state.errorShown),callback:function ($$v) {_vm.$set(_vm.$store.state, "errorShown", $$v)},expression:"$store.state.errorShown"}},[_vm._v(" "+_vm._s(_vm.$store.state.errorMessage)+" ")]),_c('v-snackbar',{attrs:{"multi-line":"","elevation":"24","top":"","right":"","timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.$store.commit('setSuccessMessage', '')}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.$store.state.successShown),callback:function ($$v) {_vm.$set(_vm.$store.state, "successShown", $$v)},expression:"$store.state.successShown"}},[_vm._v(" "+_vm._s(_vm.$store.state.successMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }